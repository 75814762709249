import "./FingerOpportunities.scss";
import plusImg from "../../../img/svgicons/plus.svg";
import FingerOpportunitiesItem from "./FingerOpportunitiesItem/FingerOpportunitiesItem";

function FingerOpportunities() {
  function importAll(image) {
    return image.keys().map(image);
  }
  const fingerOpportunitiesItems = [
    {
      title: "Данные canvas",
      text: `Отпечатки canvas работают создавая html5 canvas элемент и когда пользователь посещает веб-сайт, его браузер получает указание 'нарисовать' скрытую строку текста или другую графику в этом элементе. Попробуйте PerfectCanvas для наиболее точной замены canvas. Затем полученное изображение преобразуется в массив данных, которые могут иметь небольшие различия в разных браузерах и на разных платформах. Это различие делает данные изображения довольно уникальными и, таким образом, может использоваться для отслеживания пользователей.`,
      link: "#",
    },
    {
      title: "Данные webgl",
      text: `Отпечатки webgl работают создавая html5 canvas элемент и когда пользователь посещает веб-сайт, его браузер получает указание 'нарисовать' какие-то элементы 3d графики в этом элементе. Затем полученное изображение преобразуется в массив данных, которые могут иметь небольшие различия в разных браузерах и на разных платформах. Это различие делает данные изображения довольно уникальными и, таким образом, может использоваться для отслеживания пользов`,
      link: "#",
    },
    {
      title: "Свойства видеокарты",
      text: `Среди возможностей webgl api есть методы, которые позволяют сайту получать свойства вашего реального оборудования. Любой сайт, который вы посещаете, может читать эти свойства без разрешения пользователя. Эта информация часто отличается для разных машин и может использоваться для создания уникального отпечатка отдельно или в сочетании с другими методами. Среди свойств содержится: реальное название и производитель видеокарты, поддерживаемые расширения, аппаратные возможности и т. д.`,
      link: "#",
    },
    {
      title: "Данные audio",
      text: `Используя класс AudioContext, сайт может обнаружить незначительные различия при рендеринге фиксированного аудиосигнала для формирования отпечатка. Этот метод не подразумевает воспроизведение звука, а только его рендеринг и поэтому может быть использован без ведома пользователя.`,
      link: "#",
    },
    {
      title: "Настройки audio",
      text: `Среди возможностей audio api есть методы, которые позволяют сайту получать свойства вашего реального оборудования. Любой сайт, который вы посещаете, может читать эти свойства без разрешения пользователя. В отличие от webgl, эта информация часто одинакова для разных машин и поэтому не очень опасна. Она может быть использована для создания уникального отпечатка только в сочетании с другими методами. Эта информация содержит: частоту дискретизации звуковой карты, максимальное количество каналов, и т. д.`,
      link: "#",
    },
    {
      title: "Список шрифтов",
      text: `С помощью javscript сайт может перечислять шрифты, установленные на компьютере. Список шрифтов зависит от установленного программного обеспечения и, следовательно, может быть уникальным идентификатором, используемым для отслеживания пользователя, особенно когда он объединен с другими методами. Использование небольшого количества шрифтов не опасно, и это происходит на каждом сайте, но если количество шрифтов достаточно большое, это определенно означает, что сайт получает отпечаток браузера.`,
      link: "#",
    },
    {
      title: "Webrtc ip",
      text: `С помощью webrtc сайт может получить ваш реальный ip. Первоначально эта информация используется для облегчения прямого соединения между двумя браузерами, но она также может быть использована для получения реального ip и поэтому составляют огромную угрозу безопасности. Это означает, что даже если вы используете vpn или прокси, сайт все равно может получить ваш реальный ip без вашего разрешения.`,
      link: "#",
    },
    {
      title: "Язык браузера",
      text: ` Свойства navigator.language и navigator.languages содержат информацию о языке системы. Этот метод сам по себе не опасен и часто используется для определения настроек локализации. Тем не менее, он также может быть использован для обнаружения различий между ip прокси и языком браузера.`,
      link: "#",
    },
    {
      title: "Часовой пояс",
      text: `Есть несколько способов, с помощью которых сайт может обнаружить часовой пояс системы. Эти методы сами по себе не опасны и часто используются для определения настроек локализации. Тем не менее, они также могут быть использованы для обнаружения расхождений между ip прокси и часовым поясом браузера`,
      link: "#",
    },
    {
      title: "Список плагинов",
      text: `Свойства navigator.plugins и navigator.mimeTypes содержат информацию о используемых плагинах браузера. Список плагинов может быть разным для разных машин и, следовательно, может быть частью отпечатка браузера. В большинстве случаев этот метод используется для сбора информации об устройстве пользователя, но это не всегда так, например, сайт может проверить, установлена ли на вашем компьютере flash, и можно ли отображать флэш контент.`,
      link: "#",
    },
    {
      title: "Разрешение экрана",
      text: `Существует несколько способов получения информации о свойствах экрана, таких как разрешение, глубина цвета, положение и т. д. Эти свойства зависят от аппаратной части и, следовательно, могут использоваться для идентификации пользователя. Каждое из этих свойств может использоваться для получения отпечатков или же для безвредных целей, таких как улучшение рендеринга или создание мобильного интерфейса. Трудно провести различие между этими случаями, поэтому этот метод помечен как не опаснен`,
      link: "#",
    },
    {
      title: "User agent",
      text: `User Agent - это идентификатор браузера, он содержит информацию об операционной системе, типе браузера, версии, создавшей его компании. Это свойство часто используется различными библиотеками и средами и не считается опасным.`,
      link: "#",
    },
    {
      title: "Платформа",
      text: `Помимо userAgent, есть еще несколько свойств, которые содержат информацию об операционной системе, типе браузера, версии. Каждое из этих свойств может использоваться для получения отпечатков или для безвредных целей, таких как сбор статистики об устройствах, которые посещают сайт. Они также могут использоваться для поиска несоответствий, например, если navigator.userAgent не соответствует navigator.appVersion.`,
      link: "#",
    },
    {
      title: "Сенсорная поддержка",
      text: `Свойство navigator.maxTouchPoints позволяет определить, имеет ли устройство сенсорный дисплей.`,
      link: "#",
    },
    {
      title: "Емкость батареи",
      text: `Емкость батареи, а также ее уровень являются свойствами, которые могут быть использованы сайтам для отслеживания пользователей в краткосрочной перспективе.`,
      link: "#",
    },
    {
      title: "Do not track",
      text: `Настройка DoNotTrack, если она включена, сообщает сайту, что пользователь не хочет, чтобы его отслеживали. Если сайт читает эту настройки, это может означать, что он выполняет действия по отслеживанию или получению отпечатков.`,
      link: "#",
    },
    {
      title: "Геймпад",
      text: `Метод navigator.getGamepads возвращает список геймпадов, подключенных к устройству. Как и любое другое api, которое дает доступ к аппаратному обеспечению, этот метод может быть опасен`,
      link: "#",
    },
    {
      title: "Располо-жение",
      text: `Geolocation api позволяет сайту получать реальное положение устройства. Запрос не может быть сделан без ведома пользователя - он увидит специальное окно и должен согласиться предоставить сайту информацию о своем месторасположении, не смотря на это, данный метод достаточно опасен.`,
      link: "#",
    },
    {
      title: "Соединение",
      text: `Данное api предоставляет информацию о соединении, например, используется ли wi-fi или сотовая связь. Это может быть использовано для выбора контента в зависимости от скорости соединения, например, для передачи видео низкого разрешения пользователям с мобильным интернетом, но также может использоваться как один из комонентов для отпечатка в браузера.`,
      link: "#",
    },
    {
      title: "USB устройства",
      text: `Сайт может получить доступ к USB-устройствам, которые предназначены для совместной работы с данным api. Это api не будет получать доступ USB-устройствам которые не подготовленные соответствующим образом, таким как обычные флэш-накопители, поэтому он не очень опасен.`,
      link: "#",
    },
    {
      title: "Данные SVG",
      text: `Отпечатки Svg работают с использованием элемента svg: когда пользователь посещает сайт, его браузер получает указание нарисовать текст с указанным шрифтом. Затем полученный текст измеряется, ширина и высота сравниваются со стандартными значениями, чтобы определить, поддерживает ли браузер этот шрифт, или проверить, насколько правильно он отображается. С помощью этой техники сайт может получить список установленных шрифтов их свойства.`,
      link: "#",
    },
    {
      title: "Свойства navigator",
      text: "Свойства navigator.language и navigator.languages содержат информацию о языке системы. Этот метод сам по себе не опасен и часто используется для определения настроек локализации.Тем не менее, он также может быть использован для обнаружения различий между ip прокси и языком браузера.",
      link: "#",
    },
    {
      title: "Порядок заголовков",
      text: `Разные браузеры отправляют HTTP заголовки в разном порядке. При проверке порядка заголовков сайт может обнаружить реальный движок браузера, поэтому порядок заголовков также может быть частью отпечатка браузера.`,
      link: "#",
    },
    {
      title: "Тип браузера",
      text: "Свойства navigator.language и navigator.languages содержат информацию о языке системы. Этот метод сам по себе не опасен и часто используется для определения настроек локализации.Тем не менее, он также может быть использован для обнаружения различий между ip прокси и языком браузера.",
      link: "Тип браузера",
    },
  ];
  const fingerImgs = importAll(require.context("./img/", false, /\.(svg)$/));
  return (
    <div className="opportunities-finger block-dashed-top block-dashed-sides">
      <div className="container">
        <div className="plus top left">
          <img src={plusImg} alt="plus" />
        </div>
        <div className="plus top right">
          <img src={plusImg} alt="plus" />
        </div>

        <header class="block-header border-1 flex">
          <h2>
            <span>
              Возможности
              <div className="corner top left"></div>
              <div className="corner bottom right"></div>
              <div className="line-border top"></div>
              <div className="line-border right"></div>
              <div className="line-border left"></div>
              <div className="line-border bottom"></div>
            </span>
          </h2>
          <p>
            Вот список свойств, связанных с отпечатками браузера. Их можно
            изменить с помощью сервиса
            <b className="text-orange"> FingerprintSwitcher</b>
          </p>
        </header>

        <main className="opportunities-finger__row">
          {fingerOpportunitiesItems.map((item, i) => {
            return (
              <FingerOpportunitiesItem
                img={fingerImgs[i]}
                title={item.title}
                text={item.text}
                link={item.link}
              />
            );
          })}
        </main>
      </div>
    </div>
  );
}
export default FingerOpportunities;
